import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import RichTextRenderer from 'components/RichTextRenderer'
import PostLayout from 'components/PostLayout'
import Container from 'components/Container'
import Seo from 'components/Seo'
import Subscribe from 'components/Subscribe'
import { sizes } from 'styles'

const Title = styled.h1`
  font-size: 36px;
  font-family: ${({ theme }) => theme.font.text};

  @media ${sizes.desktop} {
    margin-top: 40px;
  }
`

const Description = styled.div`
  margin-bottom: 32px;
  font-size: 20px;
  font-weight: bold;
`

const StyledPostLayout = styled(PostLayout)`
  @media ${sizes.desktop} {
    > article {
      max-width: 710px;
    }
  }
`

const SubscribePage = ({ data }) => {
  const { allContentfulSubscribePage } = data
  const {
    title,
    description,
    buttonText,
  } = allContentfulSubscribePage.edges[0].node

  return (
    <>
      <Container>
        <Seo
          title="Subscribe"
          image={{ src: undefined, width: 1600, height: 900 }}
          description={undefined}
        />
        <StyledPostLayout
          mainContent={
            <>
              <Title>{title}</Title>
              <Description>
                <RichTextRenderer richText={description.json} />
              </Description>
              <Subscribe buttonText={buttonText} />
            </>
          }
        />
      </Container>
    </>
  )
}

export const pageQuery = graphql`
  query SubscribePageQuery {
    allContentfulSubscribePage {
      edges {
        node {
          title
          description {
            json
          }
          buttonText
        }
      }
    }
  }
`

export default SubscribePage
